:root {
  /*COLOR*/
  --color-background:#ffffff;
  --color-navbar: #ebebeb;

  --color-main: #f2ff61;
  --color-nfzorange: #F8700C;
  --color-lightyellow: #c9c9a0;
  --color-warningred: #ff6262;
  --color-confirmationgreen: #00ff00;
  --color-disabledgray: #bbbbbb;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-offwhite: #F7F5ED; 
  --color-box: red;

  --color-opensea: #2081E2;

  --color-text-main: #f2ff61;

  /*SIZE*/
  ---main-padding: 0px;

  ---border-radius: 0px;
  ---border-radius-button: 0px;
  ---border-radius-small: 0px;

  ---navbar-height: 100px;

  /* E Additions*/
  --color-navbarBoxSubtitle: #f2ff61;
}

@font-face {
  font-family: 'Sequel100Black-65';
  src: local('Sequel100Black-65'), url(./fonts/Sequel100Black-65.ttf) format('opentype');
}

@font-face {
  font-family: 'Sequel100Black-45';
  src: local('Sequel100Black-45'), url(./fonts/Sequel100Black-45.ttf) format('opentype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Sequel100Black-45', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-white);
}

.main {
  margin-bottom: 0px;
}

.red {
  background-color: red;
}

.componentFirst {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  position: relative;
  overflow: hidden;
}

.componentSecond {
  width: 100%;
  max-width: 1315px;
  display: flex;
  margin: 0 50px;
  flex-direction: column;
}

.textHighlight {
  color: var(--color-main);
}

.rowContainer {
  display: flex;
  flex-direction: row;
}

.columnContainer {
  display: flex;
  flex-direction: column;
}

.centeringContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

._justifyCenterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.justifyLeftContainer {
  display: flex;
  justify-content: left;
  align-items: left;
  text-align: left;
}

.justifyRightContainer {
  display: flex;
  justify-content: right !important;
  align-items: right !important;
  text-align: right !important;
}

@media only screen and (max-width: 670px) {
  .componentSecond {
    margin: 0 20px;
  }
  .main {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .componentSecond {
    margin: 0 10px;
  }
}
