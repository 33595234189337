a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.navbar {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 0% 0% 0% 0%;
  margin: 0% 0% 0% 0%;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.navbarContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px 0px 0px 0px;
  overflow: hidden;
}

.navbarLeft {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  text-align: left;
  z-index: 1;
}

.navbarCenter {
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0% 0% .5% 0%;
  padding: 0% 0% 0% 0%;
  position: absolute;
  z-index: 10;
  gap: 5%;
}

.navbarCenterButton {
  font-size: 15px;
  cursor: pointer;
}


.navbarRight {
  display: flex;
  align-items: right;
  justify-content: right;
  float: right;
  margin: 0% 0% .5% 0%;
  z-index: 1;
}


.navbarLogoImage {
  width: 20%;
  padding: 8px 0px 3px 8px;
  cursor: pointer;
}


.navbarIcon {
  width: 4%;
  padding: 0% 0% 0% 0%;
  margin: 1% 2% 0% 0%;
  cursor: pointer;
  align-items: right;
  justify-content: right;
  float: right;
}

.twitterIcon {
  padding: 0% 0% 0% 0%;
  margin: 0% 1% 0% 0%;
  width: 6%;
}



@media only screen and (max-width: 560px) {
  .navbar {
    max-width: 100vw;
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }
  
  .navbarCenter {
    padding: 0% 0% 0% 0%;
    margin: -1% 20% 0% 0%;
    align-items: left;
    justify-content: left;
    text-align: left;
  }

  .navbarCenterButton {
    font-size: 13px;
    border-width: 2px !important;
    border: solid;
    border-color: #000000;
    border-radius: 20px;
    padding: 4px 9px 2px 8px;
    white-space: nowrap;
  }

  .navbarIcon {
    width: 8%;
    
  }

  .navbarSocialsContainer {
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }

  .navbarLogoImage {
    width: 120%;
    padding: 0% 0% 0% 15%;
    margin: 0% 0% 0% 0%;
  }

  .twitterIcon {
    width: 13%;
    margin: -1% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }

  .linkedinIcon {
    padding-top: .5%;
  }

  .instagramIcon {
    padding-top: .5%;
  }

  .navbarLeft {
    margin-top: 5px;
  }
}



