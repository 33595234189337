.textGeneratorGUI {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  padding: 0% 0% 10% 0%;
  margin: 0% 0% 0% 0%;
  overflow: hidden;
}


.textGeneratorGUITextContainer {
  width: 50.5%;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: column;
}

.textGeneratorGUIText {
  text-align: left;
  color: white;
  font-size: 16px;
}

.textGeneratorGUITitle {
  text-align: left;
  color: #bbbbbb;
  text-decoration: 'none';
  font-size: 20px;
  margin: 1% 0% .25% 0%;
  white-space: nowrap;
}


.textGeneratorGUIpromptEntry {
  padding: 3px 3px 3px 3px;
  width: 50%;
  height: 144px;
  margin: .2% 0px 0px 0px;
  text-align: left;
  color: rgb(0, 0, 0);
  font-size: 16px;
}

.textGeneratorGUInegativePromptEntry {
  height: 112px;
}

.textGeneratorGUInumberEntry {
  height: 16px;
  width: 41px;
}

.textGeneratorGUIwordEntry {
  height: 16px;
  width: 123px;
}

.textGeneratorGUInumberDisplay {
  height: 16px;
  width: 41px;
  text-align: center;
  margin: 3% 0% 0% 3%;
}

.textGeneratorGUIselectList {
  padding: 0px 0px 1px 0px;
  width: 40%;
  font-size: 16px;
  margin: 0px 6px 0px 0px;
}



.textGeneratorGUIdropdownList {
  width: 50px;
  padding: 0px 0px 1px 0px;
  font-size: 16px;
  margin: 0px 6px 0px 0px;
}

.textGeneratorGUIchecklist {
  flex-direction: column;
  display: flex;
  color: #000000;
  gap: 8px;
  background-color: #ffffff;
  width: 50.5%;
  padding: 3px 0px 3px 0px;
  font-size: 15px;
  color: #000000;
  font-family: 'Arial';
}

.textGeneratorGUImodelOption {
  cursor: pointer;
}


.textGeneratorGUIsubmitButton {
  width: 180px;
  font-size: 18px;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 2px 0px 2px 0px !important;
  font-family: "Arial";
  color: #000000;
  background-color: #dbdbdb;
  text-align: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
  cursor: default;
}

.textGeneratorGUIgenerationTimeContainer {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
}

.textGeneratorGUIpauseButton {
  width: 100px;
}

.textGeneratorGUIpromptContainer {
  flex-direction: row;
  margin: 0% 0% 0% 0%;
  padding: 0% 0% 0% 0%;
}

.textGeneratorGUIfloatRightButton {
  width: 260px;
  margin: 1.5% 0% 0.5% 0%;
  white-space: nowrap;
}

.textGeneratorGUIcopyNegativePromptTitle {
  width: 70%;
}



.textGeneratorGUIgeneratedImage {
  width: 40%;
  padding: 2% 30% 2% 30%;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
}


.textGeneratorGUImodelDescription {
  background-color: #ffffff;
  font-size: 15px;
  color: #000000;
  font-family: 'Arial';
  padding: 1.5% 1% .5% 1%;
  margin: 0px 0px 0px 0px;
}

.textGeneratorGUIgeneratedText {
  background-color: #ffffff;
  font-size: 15px;
  color: #000000;
  font-family: 'Arial';
  width: 100%;
  padding: .5% 1% .5% 1%;
  margin: 0% 0% 0% 0%;
  overflow: hidden;
}

.textGeneratorGUImodelLink {
  background-color: #ffffff;
  font-size: 15px;
  text-decoration: underline;
  color: #0008ff;
  font-family: 'Arial';
  padding: .5% 1% 1.5% 1%;
  margin: 0px 0px 0px 0px;
}


.textGeneratorGUIallOutputContainer {
  width: 50.5%;
  align-items: center;
  justify-content: center;
}

.textGeneratorGUImodelTitleContainer {
  display: flex;
  flex-direction: row;
}

.textGeneratorGUItextOutputContainer {
  display: flex;
  flex-direction: row;
  gap: 1%;
}

.textGeneratorGUIcopiedMessage {
  color: #ffffff;
  text-align: center;
  align-items: center;
  margin: 1.5% 0% 0% 3%;
  font-size: 14px;
  opacity: 0;
}

.banner_textGeneratorGUIContainer {
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0% 0% 0% 0%;
  width: 100%;
  overflow: hidden;
}

.banner_textGeneratorGUI {
  width: 100%;
  margin: 5% 0% 1% 0%;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-radius: 0px;
  border: solid;
  border-color: #ffffff;
}

.banner_textGeneratorGUITitleContainer {
  z-index: 10;
  color: #ffffff;
  background-color: #000000;
  border-radius: 25px;
  padding: .25% 2% 1% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner_textGeneratorGUITitle {
  font-size: 35px;
}

.banner_textGeneratorGUISubTitle {
  font-size: 13px;
  color: #848484;
}

.textGeneratorGUIrow {
  display: flex;
  flex-direction: row;
}

.textGeneratorGUIimageOutputSizeTitle {
  color: #848484;
  font-size: 14px;
}

.textGeneratorGUIhelperText {
  color: #848484;
  margin: 0% 0% 0% 1%;
  font-size: 15px;
}

@media only screen and (max-width: 560px) {
  .textGeneratorGUI {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }
  

  .textGeneratorGUITextContainer {
    padding: 0% 0% 0% 0%;
    margin: 0% 0% 0% 0%;
    width: 90%;
  }

  .textGeneratorGUITitle {
    font-size: 19px;
    padding: 3% 0% 0% 0%;
    margin: 0% 0% 1% 0%;
  }


  .textGeneratorGUIpromptEntry {
    width: 89%;
    margin: 0% 0% 0% 0%;
  }

  .textGeneratorGUILink {
    width: 90%;
    padding: 0% 0% 0% 0%;
    margin: 0% 0% 0% 0%;
  }

  .textGeneratorGUImodelDescription {
    width: 98%;
    margin: 0% 0% 0% 0%;
    padding-bottom: 5%;
  }

  .textGeneratorGUImodelLink {
    width: 98%;
    margin: 0% 0% 0% 0%;
  }

  .textGeneratorGUIselectList {
    width: 100%;
  }

  .textGeneratorGUIchecklist {
    width: 90%;
  }

  .textGeneratorGUIsubmitButton {
    margin-top: 3%;
  }

  .banner_textGeneratorGUI {
    width: 150%;
  }

  .banner_textGeneratorGUIContainer {
    max-width: 100vw;
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }

  .banner_textGeneratorGUITitleContainer {
    border-radius: 10px;
    border-width: 2px;
    padding: 1% 3% 2.5% 3%;
    margin: 0% 0% 0% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .textGeneratorGUIallOutputContainer {
    width: 90%;
  }
  
  .banner_textGeneratorGUITitle {
    font-size: 25px;
  }
  
  .banner_textGeneratorGUISubTitle {
    font-size: 10px;
  }

  .textGeneratorGUIfloatRightButton {
    margin: 4.5% 0% 1% 0%;
    padding: 0% 0% 0% 0%;
    width: 150px;
  }

  .textGeneratorGUInumberEntry {
    width: 48px;
  }

  .textGeneratorGUIdropdownList {
    width: 58px;
  }

  .textGeneratorGUInumberDisplay {
    margin: 4% 0% 0% 2%;
    width: 48px;
  }

  .textGeneratorGUIwordEntry {
    width: 40%;
  }
  
  .textGeneratorGUIgenerateButton {
    font-size: 17px;
    width: 150px;
    margin-top: 15px;
  }

  .textGeneratorGUItextOutputContainer {
    flex-direction: column;
    gap: 10px;
  }
}
