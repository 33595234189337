.about {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 10% 0% 10% 0%;
  margin: 0% 0% 0% 0%;
  overflow: hidden;
}

.aboutLeftContainer {
  flex: 50%;
  display: flex;
  justify-content: flex-end;
  padding: 0% 0% 0% 10%;
}

.aboutRightContainer {
  flex: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.aboutImage {
  border-radius: 50px;
  width: 80%;
  align-items: left;
  justify-content: left;
  float: left;
}


.aboutTextContainer {
  width: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
}

.aboutText {
  text-align: left;
  color: white;
  font-size: 16px;
  border-radius: 3px;
}

.aboutTitle {
  font-size: 23px;
  text-decoration: underline;
}

.aboutSubtitle {
  color: #bbbbbb;
}

.aboutLink {
  width: 45%;
  color: #7CE2F9;
  text-decoration: none;
  white-space: nowrap;
}

.aboutLinkContainer {
  width: 21%;
}



@media only screen and (max-width: 560px) {
  .aboutBoxIMG {
    width: 100%;
  }

  .about {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100vw;
    margin: 0% 0% 0% 0%;
    padding: 15% 0% 20% 0%;
  }

  .aboutRight {
    align-items: center;
    justify-content: center;
  }


  .aboutLeftContainer {
    padding: 0% 5% 0% 5%;
    margin: 0% 0% 0% 0%;
  }

  .aboutTextContainer {
    padding: 0% 0% 0% 0%;
    margin: 0% 0% 0% 0%;
  }

  .aboutTitle {
    font-size: 19.5px;
  }

  .aboutTagline {
    font-size: 14px;
  }

  .aboutImage {
    width: 90%;
    padding: 0% 0% 0% 5%;
    margin: 10% 0% 0% 0%;
  }

  .aboutLink {
    width: 100%;
  }

  .aboutLinkContainer {
    width: 40%;
  }
}
