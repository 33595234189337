.imageGeneratorGUI {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  padding: 0% 0% 10% 0%;
  margin: 0% 0% 0% 0%;
  overflow: hidden;
}






.imageGeneratorGUItextContainer {
  width: 50.5%;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: column;
}

.imageGeneratorGUIText {
  text-align: left;
  color: white;
  font-size: 16px;
}

.imageGeneratorGUITitle {
  text-align: left;
  color: #bbbbbb;
  text-decoration: 'none';
  font-size: 20px;
  margin: 1% 0% .25% 0%;
  white-space: nowrap;
}


.imageGeneratorGUIpromptEntry {
  padding: 3px 3px 3px 3px;
  width: 50%;
  height: 144px;
  margin: .2% 0px 0px 0px;
  text-align: left;
  color: rgb(0, 0, 0);
  font-size: 16px;
}

.imageGeneratorGUInegativePromptEntry {
  height: 112px;
}

.imageGeneratorGUInumberEntry {
  height: 16px;
  width: 41px;
}

.imageGeneratorGUInumberDisplay {
  height: 16px;
  width: 41px;
  text-align: center;
  margin: 3% 0% 0% 3%;
}




.imageGeneratorGUIdropdownList {
  width: 50px;
  padding: 0px 0px 1px 0px;
  font-size: 16px;
  margin: 0px 6px 0px 0px;
}

.imageGeneratorGUIcheckList {
  flex-direction: column;
  display: flex;
  color: #000000;
  gap: 8px;
  background-color: #ffffff;
  width: 50.5%;
  padding: 3px 0px 3px 0px;
  font-size: 15px;
  color: #000000;
  font-family: 'Arial';
}

.imageGeneratorGUIsizeOption {
  cursor: pointer;
}

.imageGeneratorGUImodelOption {
  cursor: pointer;
}


.imageGeneratorGUIsubmitButton {
  width: 180px;
  font-size: 18px;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 2px 0px 2px 0px !important;
  font-family: "Arial";
  color: #000000;
  background-color: #dbdbdb;
  text-align: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 1);
  cursor: default;
}


.imageGeneratorGUIgenerationTimeContainer {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
}

.imageGeneratorGUIpauseButton {
  width: 100px;
}

.imageGeneratorGUIpromptContainer {
  flex-direction: row;
  margin: 0% 0% 0% 0%;
  padding: 0% 0% 0% 0%;
}

.imageGeneratorGUIfloatRightButton {
  width: 260px;
  margin: 1.5% 0% 0.5% 0%;
  white-space: nowrap;
}

.imageGeneratorGUIcopyNegativePromptTitle {
  width: 50%;
}



.imageGeneratorGUIgeneratedImage {
  width: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0px 10px 10px 0px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
}


.imageGeneratorGUImodelDescription {
  background-color: #ffffff;
  font-size: 15px;
  color: #000000;
  font-family: 'Arial';
  padding: 1.5% 1% .5% 1%;
  margin: 0px 0px 0px 0px;
}

.imageGeneratorGUImodelLink {
  background-color: #ffffff;
  font-size: 15px;
  text-decoration: underline;
  color: #0008ff;
  font-family: 'Arial';
  padding: .5% 1% 1.5% 1%;
  margin: 0px 0px 0px 0px;
}


.imageGeneratorGUIallOutputContainer {
  width: 50.5%;
  align-items: center;
  justify-content: center;
}

.imageGeneratorGUImodelTitleContainer {
  display: flex;
  flex-direction: row;
}

.imageGeneratorGUIimageOutputContainer {
  display: flex;
  flex-direction: row;
}

.imageGeneratorGUIcopiedMessage {
  color: #ffffff;
  text-align: center;
  align-items: center;
  margin: 1.5% 0% 0% 3%;
  font-size: 14px;
  opacity: 0;
  white-space: nowrap;
}

.imageGeneratorGUIbannerContainer {
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0% 0% 0% 0%;
  width: 100%;
  overflow: hidden;
}

.imageGeneratorGUIbanner {
  width: 100%;
  margin: 5% 0% 1% 0%;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-radius: 0px;
  border: solid;
  border-color: #ffffff;
}

.imageGeneratorGUIbannerTitleContainer {
  z-index: 10;
  color: #ffffff;
  background-color: #000000;
  border-radius: 25px;
  padding: .25% 2% 1% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageGeneratorGUIbannerTitle {
  font-size: 35px;
}

.imageGeneratorGUIbannerSubTitle {
  font-size: 13px;
  color: #848484;
}

.imageGeneratorGUIrow {
  display: flex;
  flex-direction: row;
}

.imageGeneratorGUIimageOutputSizeTitle {
  color: #848484;
  font-size: 14px;
}

.imageGeneratorGUIhelperText {
  color: #848484;
  margin: 0% 0% 0% 1%;
  font-size: 15px;
}



@media only screen and (max-width: 560px) {
  .imageGeneratorGUI {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }
  

  .imageGeneratorGUItextContainer {
    padding: 0% 0% 0% 0%;
    margin: 0% 0% 0% 0%;
    width: 90%;
  }

  .imageGeneratorGUITitle {
    font-size: 19px;
    padding: 3% 0% 0% 0%;
    margin: 0% 0% 1% 0%;
  }


  .imageGeneratorGUIpromptEntry {
    width: 89%;
    margin: 0% 0% 0% 0%;
  }

  .imageGeneratorGUILink {
    width: 90%;
    padding: 0% 0% 0% 0%;
    margin: 0% 0% 0% 0%;
  }

  .imageGeneratorGUImodelDescription {
    width: 98%;
    margin: 0% 0% 0% 0%;
    padding-bottom: 5%;
  }

  .imageGeneratorGUImodelLink {
    width: 98%;
    margin: 0% 0% 0% 0%;
  }

  .imageGeneratorGUIselectList {
    width: 100%;
  }

  .imageGeneratorGUIcheckList {
    width: 90%;
  }



  .imageGeneratorGUIsubmitButton {
    margin-top: 2%;
    font-size: 14px;
    width: 120px;
  }

  .imageGeneratorGUIbanner {
    width: 150%;
  }

  .imageGeneratorGUIbannerContainer {
    max-width: 100vw;
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }

  .imageGeneratorGUIbannerTitleContainer {
    border-radius: 10px;
    border-width: 2px;
    padding: 1% 3% 2.5% 3%;
    margin: 0% 0% 0% 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .imageGeneratorGUIallOutputContainer {
    width: 90%;
  }
  
  .imageGeneratorGUIbannerTitle {
    font-size: 25px;
  }
  
  .imageGeneratorGUIbannerSubTitle {
    font-size: 10px;
  }

  .imageGeneratorGUIfloatRightButton {
    margin: 13px 0px 8px 0px !important;
  }

  .imageGeneratorGUInumberEntry {
    width: 48px;
  }

  .imageGeneratorGUIimageOutputContainer {
    flex-direction: column;
  }

  .imageGeneratorGUIdropdownList {
    width: 58px;
  }

  .imageGeneratorGUInumberDisplay {
    margin: 4% 0% 0% 2%;
    width: 48px;
  }

  .imageGeneratorGUIcopiedMessage {
    margin: 3% 0% 0% 3%;
  }

  .imageGeneratorGUIgenerateButton {
    font-size: 17px;
    width: 150px;
    margin-top: 15px;
  }
}
