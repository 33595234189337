.banner_toolsContainer {
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0% 0% 0% 0%;
  width: 100%;
  overflow: hidden;
  background-color: #000000;
}

.banner_tools {
  width: 100%;
  margin: 0% 0% 0% 0%;
  padding: 3% 0% 0% 0%;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-radius: 0px;
  overflow: hidden;
  background-color: #000000;
}

.banner_toolsTitleContainer {
  z-index: 10;
  color: #ffffff;
  background-color: #000000;
  border: solid;
  border-color: #ffffff;
  /* border-radius: 25px; */
  padding: .25% 2% 1% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* box-shadow: 8px 15px 15px 0px rgba(0, 1, 1, 1); */
  mask-image: linear-gradient(to right, transparent 0%, black 25%, black 75%, transparent 100%);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  width: 90%;
}

.banner_toolsTitle {
  font-size: 45px;
}

.banner_toolsSubTitle {
  font-size: 13px;
  color: #848484;
}


@media only screen and (max-width: 600px) {
  .banner_tools {
    padding: 5% 0% 5% 0%;
  }

  .banner_toolsContainer {
    max-width: 100vw;
    margin: 0% 0% 0% 0%;
  }

  .banner_toolsTitleContainer {
    border-radius: 10px;
    border-width: 2px;
    padding: 6px 0px 6px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .banner_toolsTitle {
    font-size: 27px;
  }
  
  .banner_toolsSubTitle {
    font-size: 11px;
  }
}
