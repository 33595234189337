.footer {
  width: 100%;
  overflow: hidden;
  background-color: black;
  padding: 2.5% 0% 0% 0%;
}

.footerContainer {
  margin: 0% 0% 0% 0%;
  padding: 2% 0% 0% 0%;
  
}

.footerTextContainer {
  color: white;
}

.creatorAttributionContainer {
  text-align: right;
  align-items: right;
  display: inline;
  font-size: 11px;
}


.creatorAttributionText {
  text-align: right;
  display: inline;
  align-items: right;
  color: #656565;
}

.creatorAttributionLink {
  text-align: right;
  align-items: right;
  margin-right: 1%;
  color: #929292;
}



@media only screen and (max-width: 560px) {
  .footer {
    width: 100%;
    padding: 10% 0% 0% 0%;
  }

  .footerContainer {
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }
}
