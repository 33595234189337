/*1st background1.png*/
/*.background1 {
  object-fit: fill;
  width: 203%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: -50px 0px -3900px 0px;
}*/

.background {
  background-color: #000000;
  display: flex;
  justify-content: center;
  padding: 0% 0% 0% 0%;
  margin: 0% 0% 0% 0%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: absolute;
  will-change: transform;
  width: 100%;
  min-height: 100%;
}

.backgroundImage {
  height: 4797px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin: 0px 0px 0px 0px;
}


@media only screen and (max-width: 1400px) {
  .background {
    max-width: 100vw;
  }
  
  .backgroundImage {
    width: 100vw;
  }
}
